<template>
  <div class="inner-section application-form-wrapper">
    <bread-cumb />
    <body-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('teaGardenPanel.broker_application') + ' ' + ($route.query.id ? $t('globalTrans.update') : $t('globalTrans.apply')) }}</h4>
      </template>
      <template v-slot:body>
          <b-overlay :show="isLoading">
            <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
            <b-form @submit.prevent="handleSubmit(saveUpdate)" @reset.prevent="reset" autocomplete="off"  enctype="multipart/form-data">
                <b-row v-if="application.application?.status == 9 && application.application?.return_remarks">
                    <b-col>
                        <div>
                        <b-alert show dismissible variant="danger">
                            <strong>{{ $t('teaGardenBtriService.return_remarks_note') }}</strong>: {{ application.application?.return_remarks }}
                        </b-alert>
                        </div>
                    </b-col>
                </b-row>
                <!-- input-form -->
                <InputForm @get-renew-data="getRenewData" @license_expired="isLicenseExpired" :application="application" :isRenew="isRenew" :licenseSearch="true"/>
                <!-- attachment-form -->
                <AttachmentForm :application="application" :isRenew="isRenew"/>
                <!-- submit buttons -->
                <b-row class="text-right mb-3">
                <b-col v-show="!is_license_expired">
                    <b-button v-if="application?.application?.status !== 9" type="button" @click="saveUpdate(1)" variant="warning" class="mr-2 btn-sm">{{ $t('globalTrans.draft') }}</b-button>
                    <b-button v-if="application?.application?.status == 9" type="button" @click="saveUpdate(9)" variant="warning" class="mr-2 btn-sm">{{ $t('globalTrans.submit') }}</b-button>
                    <b-button v-if="application?.application?.status !== 9" type="button" @click="saveUpdate(2)" variant="success" class="mr-2 btn-sm">{{ $t('teaGardenBtriService.submit_n_payment') }}</b-button>
                    <b-button variant="danger" class="btn-sm" @click="back()">{{ $t('globalTrans.cancel') }}</b-button>
                </b-col>
                </b-row>
            </b-form>
            </ValidationObserver>
          </b-overlay>
      </template>
    </body-card>
  </div>
</template>
<script>
// import RestApi, { authServiceBaseUrl } from '@/config/api_config'
import BreadCumb from '@/components/BreadCumb.vue'
import InputForm from '../license-components/InputForm.vue'
import AttachmentForm from '../license-components/AttachmentForm.vue'
import { mapGetters } from 'vuex'
import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
import { brokersApplicationStoreApi, brokersApplicationUpdateApi, existingRetailerApplicationDataApi } from '../../api/routes'
export default {
  components: { BreadCumb, InputForm, AttachmentForm },
  name: 'Form',
  data () {
    return {
        baseUrl: teaGardenServiceBaseUrl,
        valid: null,
        saveBtnName: this.$t('globalTrans.update'),
        errors: [],
        isRenew: false,
        application: {
            application_type: 1,
            service_id: 30,
            garden_id: 0,
            parent_id: null,
            user_id: 0,
            area_type_id: 0,
            division_id: 0,
            district_id: 0,
            city_corporation_id: 0,
            pauroshoba_id: 0,
            upazila_id: 0,
            union_id: 0,
            c_area_type_id: 0,
            c_division_id: 0,
            c_district_id: 0,
            c_city_corporation_id: 0,
            c_pauroshoba_id: 0,
            c_upazila_id: 0,
            c_union_id: 0,
            w_area_type_id: 0,
            w_division_id: 0,
            w_district_id: 0,
            w_city_corporation_id: 0,
            w_pauroshoba_id: 0,
            w_upazila_id: 0,
            w_union_id: 0,
            business_type_id: 0,
            trade_license_fiscal_year_id: 0
        },
        application_type: 1,
        draft: 1,
        isLoading: false,
        is_license_expired: false
    }
  },
  created () {
    if (this.$route.query.id) {
        this.isLoading = true
        this.getData()
        this.application.application_type = this.application.application.application_type
        this.isLoading = false
    } else {
        this.isLoading = false
        this.getExistingRetailerApplication()
    }
    this.application.user_id = this.authUser.user_id
  },
  computed: {
    loading: function () {
        return this.$store.state.commonObj.loading
    },
    ...mapGetters({
        commonProfile: 'Auth/commonProfile',
        authUser: 'Auth/authUser'
    }),
    id () {
        return this.$route.query.id
    }
  },
  methods: {
    getRenewData (data) {
      this.isRenew = false
      this.application = data.taggable
      this.application.application_type = 2
      this.application.service_id = data.service_id
      this.application.parent_id = data.taggable.id
      this.application.old_license_expire_date = data.expired_date
      this.renewFileRequired()
    },
    renewFileRequired () {
      this.application.old_license_attachment = ''
      this.application.company_memorandum = ''
      this.application.certificate_of_incor = ''
      this.application.agreement_attachment = ''
      this.application.farm_reg_certificate = ''
      this.application.necessary_documents = ''
      this.application.trade_license = ''
      this.application.vat_certificate = ''
      this.application.bank_solvency_certificate = ''
      this.application.director_biodata = ''
      this.application.garden_owner_certificate = ''
      this.application.agreement_paper = ''
    },
    back () {
        return this.$router.go(-1)
    },
    async getData () {
        const tmpData = this.$store.state.list.find(item => item.id === parseInt(this.id))
        const tmp = JSON.parse(JSON.stringify(tmpData))
        this.application = Object.assign({}, tmp, { application_type: '', service_id: tmp.application.service_id })
    },
    async saveUpdate (status = 1) {
        var check = await this.$refs.form.validate()
        this.application.status = status
        if (check) {
            let result = ''
            this.isLoading = true

            if (this.id) {
                result = await RestApi.putData(teaGardenServiceBaseUrl, `${brokersApplicationUpdateApi}/${this.id}`, this.application)
            } else {
                result = await RestApi.postData(teaGardenServiceBaseUrl, brokersApplicationStoreApi, this.application)
            }

            if (result.success) {
                this.$toast.success({
                title: 'Success',
                message: this.app_id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
                color: '#D6E09B'
                })

                if (result.is_payment) {
                    const baseTableId = result.base_table_id
                    this.$router.push({ name: 'tea_garden_panel.payment', params: { id: baseTableId }, query: { from: 'tea_garden_panel.broker_license_application' } })
                } else {
                    this.$router.push({ name: 'tea_garden_panel.broker_license_application' })
                }
            } else {
                if (result.exist_license) {
                    this.isLoading = false

                    this.$toast.error({
                      title: this.$t('globalTrans.error'),
                      message: result.message,
                      color: '#ee5253'
                    })
                }

                this.$refs.form.setErrors(result.errors)
                this.$toast.error({
                title: this.$t('globalTrans.error'),
                message: this.$t('globalTrans.form_error_msg'),
                color: '#ee5253'
                })
            }

            this.isLoading = false
        }
    },
    async getExistingRetailerApplication () {
        let result = ''
        this.isLoading = true

        result = await RestApi.postData(teaGardenServiceBaseUrl, existingRetailerApplicationDataApi)

        if (result.success) {
            this.application = Object.assign({}, this.application, result.data)
        } else {
          this.application = Object.assign({}, this.application, { applicant_name_en: this.authUser.name, applicant_name_bn: this.authUser.name_bn, mobile: this.authUser.mobile, email: this.authUser.email })
        }

        this.isLoading = false
    },
    isLicenseExpired (data) {
      this.is_license_expired = data
    }
  }
}
</script>
